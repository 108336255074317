<template>
    <div>
        <Alert />
        <div
            class="flex items-center justify-center"
            :style="`background-image: url(${require('@/assets/images/login-bg.jpg')})`"
            style="background-size: cover; height: 100vh"
        >
            <div class="w-50-l w-90">
                <!-- Simplebks logo -->
                <div class="tc pb4">
                    <img
                        src="../../../assets/images/simplebks-logo.png"
                        style="width: 180px; height: auto"
                        alt="simplebks-logo"
                    />
                </div>
                <!-- Simplebks logo -->

                <!-- login/Input -->
                <div style="background-color: white">
                    <form
                        class="center pa5-l pa3"
                        @submit.prevent="handleJoinTeam"
                        :class="{ valid: !v$.$error && v$.$dirty, error: v$.$error }"
                    >
                        <h2 class="tc">Please fill the form below to complete your onboarding</h2>
                        <div class="flex flex-column pb3">
                            <label class="pb2">Email</label>
                            <input
                                class="form-sign"
                                type="email"
                                name="email"
                                placeholder="beezo@amazon.com"
                                id="email"
                                readonly
                                v-model="state.email"
                            />
                        </div>

                        <div class="flex makeFlexResponsive justify-between pb3" style="gap: 1rem">
                            <div class="pb3 w-100">
                                <label class="pb2 makeBlock">First Name</label>
                                <input
                                    class="form-sign w-100"
                                    type="text"
                                    name="firstName"
                                    placeholder="eg. Jane"
                                    id="firstName"
                                    v-model="state.firstName"
                                />
                                <div class="input-errors mt1" v-for="error of v$.firstName?.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                </div>
                            </div>

                            <div class="pb3 w-100">
                                <label class="pb2 makeBlock">Last Name</label>
                                <input
                                    class="form-sign w-100"
                                    type="text"
                                    name="lastName"
                                    placeholder="e.g John"
                                    id="lastName"
                                    v-model="state.lastName"
                                />
                                <div class="input-errors mt1" v-for="error of v$.lastName?.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="flex makeFlexResponsive justify-between" style="gap: 1rem">
                            <div class=" pb3 w-50">
                                <label class="pb2 makeBlock">Create New Password</label>
                                <input
                                    class="form-sign w-100"
                                    type="password"
                                    name="password"
                                    placeholder="*************"
                                    id="password"
                                    v-model="state.password"
                                    v-if="hidePassword"
                                />
                                <input
                                    class="form-sign w-100"
                                    type="text"
                                    name="password"
                                    placeholder="*************"
                                    id="text"
                                    v-model="state.password"
                                    v-if="!hidePassword"
                                />
                                <font-awesome-icon
                                    v-if="!hidePassword"
                                    style="cursor: pointer"
                                    @click="togglePassword"
                                    class="field-icon"
                                    icon="eye"
                                />
                                <font-awesome-icon
                                    v-if="hidePassword"
                                    style="cursor: pointer"
                                    @click="togglePassword"
                                    class="field-icon"
                                    icon="eye-slash"
                                />
                                <div class="input-errors mt1" v-for="error of v$.password?.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                </div>
                            </div>

                            <div class=" pb3 w-50">
                                <label class="pb2 makeBlock">Confirm Password</label>
                                <input
                                    class="form-sign w-100"
                                    type="password"
                                    name="password2"
                                    placeholder="*************"
                                    id="password2"
                                    v-model="state.password2"
                                    v-if="hidePassword2"
                                />
                                <input
                                    class="form-sign w-100"
                                    type="text"
                                    name="password2"
                                    placeholder="*************"
                                    id="password2"
                                    v-model="state.password2"
                                    v-if="!hidePassword2"
                                />
                                <font-awesome-icon
                                    v-if="!hidePassword2"
                                    style="cursor: pointer"
                                    @click="togglePassword2"
                                    class="field-icon"
                                    icon="eye"
                                />
                                <font-awesome-icon
                                    v-if="hidePassword2"
                                    style="cursor: pointer"
                                    @click="togglePassword2"
                                    class="field-icon"
                                    icon="eye-slash"
                                />
                                <div
                                    class="input-errors"
                                    v-for="error of v$.password2?.$errors"
                                    :key="error.$uid"
                                    style="margin-top:25px"
                                >
                                    <div class="error-msg">{{ error.$message }}</div>
                                </div>
                            </div>
                        </div>
                        <button class="mt3 w-100 submit-btn" type="submit" :disabled="$store.state.Admin.disabledButton">
                            {{ $store.state.Admin.disabledButton ? 'Joining, please wait...' : 'Join Your Team' }}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
    name: 'JoinTeam',
    components: { Alert },

    setup() {
        const router = useRouter()
        const store = useStore()
        const { query } = router?.currentRoute?.value
        const route = useRoute()
        const hidePassword = ref(true)
        const hidePassword2 = ref(true)

        const rules = {
            email: { required, email },
            password: { required },
            password2: {
                required
                // sameAsPassword: sameAs('password')
            },
            firstName: { required },
            lastName: { required }
        }

        const state = reactive({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            password2: ''
            // partnerID: route.params.id
        })

        const v$ = useVuelidate(rules, state)

        const getEmailAndHashOnMount = () => {
            state.email = route.query.email
        }

        const handleJoinTeam = () => {
            v$.value.$touch()
            if (v$.value.$invalid) return

            if (state.password !== state.password2) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Password and Confirm Password must be same',
                    success: false
                })
            } else {
                const formData = {
                    firstName: state.firstName,
                    lastName: state.lastName,
                    email: state.email,
                    password: state.password,
                    passwordComfirmation: state.password2
                }

                store.dispatch('Admin/joinTeamUsingInvite', { id: route.params.id, payload: formData, hash: query.hash })
            }
        }

        const togglePassword = () => {
            hidePassword.value = !hidePassword.value
        }

        const togglePassword2 = () => {
            hidePassword2.value = !hidePassword2.value
        }

        onMounted(() => {
            getEmailAndHashOnMount()
            // console.log('query', query)
            // console.log('route', route.params.id)
        })

        return { state, handleJoinTeam, getEmailAndHashOnMount, v$, hidePassword, hidePassword2, togglePassword, togglePassword2 }
    }
}
</script>

<style scoped>
.error-msg {
    color: red;
}

.field-icon {
    float: right;
    margin-left: 313px;
    margin-top: -34px;
    position: relative;
    z-index: 2;
    margin-right: 15px;
}

@media (min-width: 768px) and (max-width: 800px) {
    .field-icon {
        margin-left: 295px;
    }
}
</style>
